@import "@Static/css/common";

.button {
    user-select: none;
    word-break: break-word;
    border: none;
    cursor: pointer;
    font-size: inherit;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.7;
    filter: grayscale(0.5);
}

.roundedCornersButton {
    border-radius: 6px;
    padding: 7px 27px;
}

.pillButton {
    border-radius: 1000000rem;
    padding: 3px 11px;
}

.plainButton {
    padding: 0;
    background-color: transparent;
}
