@import "@Static/css/common";

.heroSection {
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    padding-left: 135px;
    display: flex;
    align-items: center;
    z-index: 15;
    position: relative;
    // The ratio for this image is 1878w x 4320h
    height: #{(1878 / 4320 * 100) + "vw"};
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 90%);
    height: 696px;
    max-width: 1920px;
    margin: auto;
}
.aboutText {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 48px;
}
.sectionTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    margin-right: auto;
    height: 696px;
}
.sectionTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    margin-right: auto;
}
.shortDescription {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.1em;
    margin-bottom: 0px;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    &.mobileOnly {
        display: none;
    }
}
.heroContent {
    max-width: 457px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 44px;
    max-width: 457px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 44px;
    .title {
        font-style: normal;
        font-weight: 200;
        font-size: 45px;
        line-height: 54px;
        letter-spacing: 0.02em;
        margin-bottom: 0px;
        margin-bottom: 0px;
        text-shadow: 2px 2px 9px rgba(0, 0, 0, 0.2);
    }
    .freeProgramContainer {
        .freeProgram {
            height: 49px;
            border-radius: 27px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.04em;
            transition: all ease-in-out 0.2s;

            color: #000000;
            &:hover {
                transform: scale(1.03);
                background: transparent !important;
                border: 2px solid #ffffff;
                color: #ffffff !important;
            }
        }
    }
}

.creatorIntro {
    width: 100%;
    max-width: 1240px;
    padding: 0 20px;
    margin: 80px auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .dotMatrix {
        display: none;
    }

    .introContent {
        position: relative;
        .name {
            font-size: 48px;
            font-weight: bold;
        }
    }
    .profileImg {
        width: 40%;
        height: 40%;
        border-radius: 4px;
    }
}

.aboutLine {
    width: 87px;
    height: 0px;
    border: 1.5px solid;
    margin-left: 18px;
}

@media only screen and (min-width: 781px) and (max-width: 1080px) {
    .heroContent {
        gap: 20px;
        .title {
            font-size: 35px;
            line-height: 44px;
        }
    }
}
@media only screen and (max-width: 780px) {
    .heroSection {
        height: 560px;
    }

    .creatorIntro {
        flex-direction: column-reverse;
        margin: 48px auto;
        .profileImg {
            width: 100%;
            max-width: 400px;
            margin: auto;
            margin-bottom: 32px;
        }
        .introContent {
            .name {
                font-size: 28px;
            }
        }
    }
    .heroSection {
        min-height: 160px;
        padding-left: 0;
    }
    .heroContent {
        width: 100%;
        margin: auto;
        .freeProgramContainer {
            flex-direction: row;
            display: flex;
            margin-left: 17px;
            .freeProgram {
                height: fit-content;
                max-width: 80%;
                font-size: 14px;
                padding: 14px 18px;
            }
            .heroCtaArrow {
                display: none;
            }
        }
        .title {
            width: 95%;

            font-style: normal;
            font-weight: 200;
            font-size: 26px;
            line-height: 38px;
            letter-spacing: 0.02em;
            margin: 0px 17px 17px 17px;
        }
        .shortDescription {
            width: 95%;

            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.1em;
            margin: 0px 17px 17px 17px;
        }
    }
}

.hideInDesktop {
    @media screen and (min-width: 768px) {
        display: none;
    }
}

.hideInMobile {
    @media screen and (max-width: 768px) {
        display: none;
    }
}
