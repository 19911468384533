// WARNING: This stylesheet is being used in multiple files. Exercise caution while making changes.

// TODO - @aditya: Move this stylesheet inside the <template_name> folder directly and update imports accordingly.

@import "@Static/css/common";

.sectionTitle {
    text-align: center;
    font-size: 46px;
    line-height: 52px;
    font-weight: bold;
    letter-spacing: 0.74px;
    opacity: 1;
}

.root {
    width: 100vw;
    height: 100dvh;
    overflow-y: overlay;
    overflow-x: hidden;
    @extend .themedScrollbar;
    scroll-behavior: smooth;
    max-width: 1920px;
    margin: auto;
}

.custom_font_root {
    font-family: Jost;
    button {
        font-family: Jost;
    }
}

@media (max-width: 600px) {
    .root::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .root {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .sectionTitle {
        font-size: 28px;
        line-height: 39px;
        letter-spacing: 0.45px;
    }
}

.hideInDesktop {
    @media screen and (min-width: 768px) {
        display: none;
    }
}

.hideInMobile {
    @media screen and (max-width: 768px) {
        display: none;
    }
}
